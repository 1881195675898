import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import SEO from "../components/SEO"
import linkResolver from "../utils/linkResolver"
import Hero from "../components/hero"

const CgvPage = ({ data, pageContext: { locale }, location }) => {
  const CgvPageData = data.prismic.cgvpage
  return (
    <>
      <SEO
        title={CgvPageData.meta_title}
        desc={CgvPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: CgvPageData.title,
          subtitle: CgvPageData.subtitle,
        }}
      />
      <section className="section is-medium">
        <div className="container">
          <div className="columns is-centered">
            <div className="column hasRichText is-10">
              {RichText.render(CgvPageData.richtext, linkResolver)}
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default CgvPage

CgvPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const CgvQuery = graphql`
  query CgvQuery($uid: String!, $locale: String!) {
    prismic {
      cgvpage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        richtext
      }
    }

    site {
      ...SiteInformation
    }
  }
`
